import React, { Component } from "react";
import { NavLink } from "react-router-dom";
import Logo from "../../../public/img/ale-baja-logo-three-colors.svg";
import "./header.scss";
import Sprite from "../../../public/SVG/sprite.svg";

class Header extends Component {
  handleLinkClick() {
    document.getElementsByClassName("mobile-nav__checkbox")[0].click();
  }

  render() {
    return (
      <div>
        <header>
          <div className="mobile-nav">
            <img className="mobile-nav__logo" src={Logo} alt="logo" />
          </div>
          <input
            type="checkbox"
            className="mobile-nav__checkbox"
            id="navi-toggle"
          />
          <label htmlFor="navi-toggle" className="mobile-nav__button">
            <span className="mobile-nav__icon">&nbsp;</span>
          </label>
          <nav className="user-nav">
            <NavLink to="/" className="user-nav__homepage">
              &nbsp;
            </NavLink>
            <img className="navbar-logo" src={Logo} alt="logo" />
            <div className="user-nav__collapse">
              <ul
                className="user-nav__navbar"
                onClick={() => {
                  this.handleLinkClick();
                }}
              >
                <li className="user-nav__item">
                  <NavLink to="/" className="user-nav__link">
                    Strona główna
                  </NavLink>
                </li>
                <li className="user-nav__item">
                  <NavLink
                    to="/bajki"
                    className="user-nav__link"
                    activeClassName="user-nav__item--active"
                  >
                    Bajki
                  </NavLink>
                </li>
                <li className="user-nav__item">
                  <NavLink
                    to="/artykuly"
                    className="user-nav__link"
                    activeClassName="user-nav__item--active"
                  >
                    Artykuły
                  </NavLink>
                </li>
                <li className="user-nav__item">
                  <NavLink
                    to="/o-mnie"
                    className="user-nav__link"
                    activeClassName="user-nav__item--active"
                  >
                    O mnie
                  </NavLink>
                </li>
                <li className="user-nav__item">
                  <NavLink
                    to="/kontakt"
                    className="user-nav__link"
                    activeClassName="user-nav__item--active"
                  >
                    Kontakt
                  </NavLink>
                </li>
              </ul>
              <div className="footer__icon-box">
                <a href="https://www.instagram.com/ale_baja/?fbclid=IwAR3vhTJac3-cTJHKhiUHa7O5OtH6hpnjSAJnzbAZuC414X1XNkudl4J_rN4">
                  <svg className="footer__icon">
                    <use href={Sprite + "#icon-instagram"}></use>
                  </svg>
                </a>
                <a href="https://www.facebook.com/Alebaja1">
                  <svg className="footer__icon">
                    <use href={Sprite + "#icon-facebook2"}></use>
                  </svg>
                </a>
                <NavLink to="/kontakt">
                  <svg className="footer__icon">
                    <use href={Sprite + "#icon-envelop"}></use>
                  </svg>
                </NavLink>
              </div>
            </div>
          </nav>
        </header>
      </div>
    );
  }
}

export default Header;
