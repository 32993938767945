import React, { Component } from "react";
import { Link } from "react-router-dom";
import "./Content.scss";
import LatestData from "../../data/latest.json";

class Content extends Component {
  constructor(props) {
    super(props);

    this.state = {
      filtered: [],
      isLoading: true,
    };
  }
  componentDidMount() {
    if (window.FB) {
      window.FB.XFBML.parse();
    }
    this.setState({
      filtered:
        LatestData.latest[
          this.props.match.url.includes("/bajki") ? "fairy-tales" : "articles"
        ],
    });

    setTimeout(() => this.setState({ isLoading: false }), 1000);
  }

  componentDidUpdate(prevProps) {
    if (this.props.match.url !== prevProps.match.url) {
      this.setState({
        filtered:
          LatestData.latest[
            this.props.match.url.includes("/bajki") ? "fairy-tales" : "articles"
          ],
      });
    }
  }

  searchContent(event) {
    let currentList = [];
    let newList = [];

    if (event.target.value !== "") {
      currentList =
        LatestData.latest[
          this.props.match.url.includes("/bajki") ? "fairy-tales" : "articles"
        ];

      newList = currentList.filter((item) => {
        const lowerCaseItem = item.header.toLowerCase();
        const filter = event.target.value.toLowerCase();

        return lowerCaseItem.includes(filter);
      });
    } else {
      newList =
        LatestData.latest[
          this.props.match.url.includes("/bajki") ? "fairy-tales" : "articles"
        ];
    }
    this.setState({
      filtered: newList,
    });
  }

  render() {
    return (
      <div>
        <div className={this.state.isLoading ? "loading-spinner" : ""}></div>
        {this.props.match.url.includes("/bajki") && (
          <input
            className="article__search"
            name="text"
            type="text"
            placeholder={
              this.props.match.url.includes("/bajki")
                ? "Wyszukaj bajkę..."
                : "Wyszukaj artykuł..."
            }
            onChange={(event) => this.searchContent(event)}
            value={this.state.searchValue}
          />
        )}
        <div className="article">
          {this.state.filtered.map((latest, index) => {
            const image =
              require(`../../../public/img/${latest.slug}.jpg`) ||
              require(`../../../public/img/jak-to-wszystko-sie-zaczelo.jpg`);
            return (
              <Link
                to={`/${
                  this.props.match.url.includes("/bajki") ? "bajki" : "artykuly"
                }/${latest.slug}`}
                className="article__box"
                key={index}
              >
                <h1 className="article__header">{latest.header}</h1>
                <p className="article__content">{latest.content}</p>
                <img
                  className="article__image"
                  src={image}
                  alt="drobinek"
                ></img>
              </Link>
            );
          })}
        </div>
        <div
          className="fb-comments"
          data-href={
            this.props.match.url.includes("/bajki")
              ? "https://www.alebaja.com.pl/bajki"
              : "https://www.alebaja.com.pl/artykuly"
          }
          data-numposts="1"
          data-width="100%"
        ></div>
      </div>
    );
  }
}

export default Content;
