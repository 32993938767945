import React, { Component } from "react";
import "./post.scss";
import LatestData from "../../data/latest.json";
import Sprite from "../../../public/SVG/sprite.svg";
import Logo from "../../../public/img/ale-baja-logo-three-colors.svg";
import Typography from "@material-ui/core/Typography";
import Breadcrumbs from "@material-ui/core/Breadcrumbs";
import Link from "@material-ui/core/Link";
import Helmet from "react-helmet";
import { connect } from "react-redux";

const mapStateToProps = (state) => ({
  fontSize: state.fontSize,
});

class Post extends Component {
  constructor(props) {
    super(props);

    console.log('loading component')
    this.state = {
      fontSize: 24,
      visible: false,
      printLogo: false,
    };
  }

  componentDidMount() {
    console.log('is loading')
    if (window.FB) {
      window.FB.XFBML.parse();
    }
    window.scrollTo(0, 0);
    window.addEventListener("scroll", this.handleScroll);
  }

  print(element) {
    const printContents = document.getElementsByClassName(element)[0].innerHTML;
    const originalContents = document.body.innerHTML;

    document.body.innerHTML = printContents;

    window.print();

    document.body.innerHTML = originalContents;
    this.setState({ printLogo: false });
    window.location.reload();
  }

  handleScroll = () => {
    const currentScrollPos = window.pageYOffset;
    const visible = currentScrollPos > 120;

    this.setState({
      visible,
    });

  };

  componentWillUnmount() {
    window.removeEventListener("scroll", this.handleScroll);
  }

  render() {
    const title = this.props.match.params.title;
    const href = `https://www.alebaja.com.pl/bajki/${title}`;
    const image =
      require(`../../../public/img/${title}.jpg`) ||
      require(`../../../public/img/jak-to-wszystko-sie-zaczelo.jpg`);

    return (
      <div className="post">
        {LatestData.content.map((content, index) => {
          if (content.hasOwnProperty(title)) {
            var template = { __html: content[title].content };
          }
          return (
            content.hasOwnProperty(title) && (
              <div className="content" key={index}>
                <Helmet>
                  <meta charSet="utf-8" />
                  <title>Alebaja - {content[title].header}</title>
                </Helmet>
                <Breadcrumbs
                  className="content__breadcrumb no-print"
                  aria-label="breadcrumb"
                >
                  <Link color="inherit" href="/">
                    Strona główna
                  </Link>
                  <Link
                    color="inherit"
                    href={
                      this.props.match.url.includes("/bajki/")
                        ? "/bajki"
                        : "/artykuly"
                    }
                  >
                    {this.props.match.url.includes("/bajki/")
                      ? "Bajki"
                      : "Artykuły"}
                  </Link>
                  <Typography color="textPrimary">
                    {content[title].header}
                  </Typography>
                </Breadcrumbs>
                {this.state.printLogo && (
                  <img className="navbar-logo" src={Logo} alt="logo" />
                )}
                <div className="content__button">
                  <button
                    className="content__button--print no-print"
                    onClick={() => {
                      this.setState({ printLogo: true });
                      setTimeout(() => {
                        this.print("post");
                      }, 20);
                    }}
                  >
                    <svg className="content__icon">
                      <use href={Sprite + "#icon-printer"}></use>
                    </svg>
                    Drukuj&nbsp;
                    {this.props.match.url.includes("/bajki/")
                      ? "bajkę"
                      : "artykuł"}
                  </button>
                </div>
                <div
                  className={
                    this.state.visible ? "visible no-print" : "hidden no-print"
                  }
                >
                  <a
                    className="font-utility font-utility__increment"
                    onClick={() =>
                      this.setState((prevState) => ({
                        fontSize: prevState.fontSize + 1,
                      }))
                    }
                  >
                    +
                  </a>
                  <a
                    className="font-utility font-utility__decrement"
                    onClick={() =>
                      this.setState((prevState) => ({
                        fontSize: prevState.fontSize - 1,
                      }))
                    }
                  >
                    -
                  </a>
                </div>
                <h1 className="content__title">{content[title].header}</h1>
                <img
                  className="content__image"
                  src={image}
                  alt="drobinek"
                ></img>
                <div
                  className="content__text"
                  dangerouslySetInnerHTML={template}
                  style={{ fontSize: this.state.fontSize + "px" }}
                ></div>
                <div className="no-print">
                  <div
                    className="fb-like no-print"
                    data-href={href}
                    data-width=""
                    data-layout="button_count"
                    data-action="like"
                    data-size="small"
                    data-share="true"
                  ></div>
                  <div
                    className="fb-comments no-print"
                    data-href={href}
                    data-numposts="1"
                    data-width="100%"
                  ></div>
                </div>
              </div>
            )
          );
        })}
      </div>
    );
  }
}

export default connect(mapStateToProps, {})(Post);
