import React, { Component } from "react";
import "./AboutUs.scss";

class AboutUs extends Component {
  
  //re-parse dom after mounting for fb-comment-box
  componentDidMount() {
    if (window.FB) {
      window.FB.XFBML.parse();
    }
  }

  render() {
    return (
      <div>
        <div className="about-us__content">
          <header className="header__about-us">
            <p className="header__text">Kilka słów o mnie…</p>
          </header>
          <div className="about-us__content--text">
            <p>
              Jestem zadowoloną absolwentką studiów psychologicznych na
              Uniwersytecie Jagiellońskim. Na co dzień pracuje w Poradni
              Psychologiczno-Pedagogicznej, gdzie mam kontakt z dziećmi i
              młodzieżą w różnym wieku oraz w Liceum Ogólnokształcącym. Od
              dziecka lubiłam pisać i rysować. Już w przedszkolu stworzyłam
              swoją pierwszą prowizoryczną bajkę dla młodszego brata. A dziś no
              cóż próbuje połączyć moją miłość do pisania, rysowania z pasją
              jaką jest praca z dziećmi. Mam nadzieję, że choć w niewielkim
              stopniu mi się to uda.{" "}
              <span role="img" aria-label="smile">
                😊
              </span>
            </p>
          </div>
        </div>
      </div>
    );
  }
}

export default AboutUs;
