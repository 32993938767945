import React, { Component } from "react";
import "./contact.scss";
import ReCAPTCHA from "react-google-recaptcha";
import { Formik } from "formik";

class Contact extends Component {
  constructor(props) {
    super(props);

    this.state = {
      captchaValue: "",
      isSent: false,
    };
  }
 
  onSubmit = async (values) => {
    try {
      await fetch(`${process.env.REACT_APP_API_SITE}/api/contact`, {
        method: "POST",
        headers: {
          "Content-type": "application/json",
        },
        body: JSON.stringify(values),
      }).then(this.setState({ isSent: true }));
    } catch (values) {
      throw values;
    }
  };

  onChange = (value) => {
    this.setState({ captchaValue: value });
  };

  render() {
    return (
      <main className="contact-form">
        <div className="contact-form__desc">
          <header className="contact-form__header">
            <p className="contact-form__header--text">
              Jeżeli masz jakieś pytania, napisz do nas! :)
            </p>
          </header>
        </div>
        <Formik
          initialValues={{ email: "", subject: "", message: "" }}
          validate={(values) => {
            const errors = {};
            if (!values.email) {
              errors.email = "Pole wymagane";
            } else if (
              !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email)
            ) {
              errors.email = "Błędny adres email";
            }

            if (!values.subject) {
              errors.subject = "Pole wymagane";
            }

            if (!values.message) {
              errors.message = "Pole wymagane";
            }

            return errors;
          }}
          onSubmit={(values, { setSubmitting }) => {
            this.onSubmit(values);
            setSubmitting(false);
          }}
        >
          {({
            values,
            errors,
            touched,
            handleChange,
            handleBlur,
            handleSubmit,
            isValid,
          }) => (
            <form onSubmit={handleSubmit}>
              <div className="contact-form__group">
                <label className="contact-form__label" id="email">
                  Email
                </label>
                <input
                  className="contact-form__input"
                  name="email"
                  for="email"
                  type="email"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.email}
                />
                <div className="contact-form__error">
                  &nbsp;
                  {errors.email && touched.email && errors.email}
                </div>
              </div>
              <div className="contact-form__group">
                <label className="contact-form__label" id="subject">
                  Temat
                </label>
                <input
                  className="contact-form__input"
                  name="subject"
                  for="subject"
                  type="text"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.subject}
                />
                <div className="contact-form__error">
                  &nbsp;
                  {errors.subject && touched.subject && errors.subject}
                </div>
              </div>
              <div className="contact-form__group">
                <label className="contact-form__label" id="message">
                  Wiadomość
                </label>
                <textarea
                  className="contact-form__input"
                  name="message"
                  for="message"
                  type="text"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.message}
                />
                <div className="contact-form__error">
                  &nbsp;
                  {errors.message && touched.message && errors.message}
                </div>
              </div>
              <div className="recaptcha">
                <ReCAPTCHA
                  sitekey={process.env.REACT_APP_RECAPTCHA_KEY}
                  onChange={this.onChange}
                />
                <button
                  disabled={
                    !this.state.captchaValue || !isValid || this.state.isSent
                  }
                  className="contact-form__button"
                >
                  Wyślij wiadomość
                </button>
                {this.state.isSent && (
                  <div className="contact-form__error">
                    &nbsp;
                    <p>Twoja wiadomość została wysłana! :)</p>
                  </div>
                )}
              </div>
            </form>
          )}
        </Formik>
        <div className="contact-form__text">
          <p>
            Wszystkie bajki, artykuły i grafiki na stronie alebaja.com.pl są
            mojego autorstwa. Można je kopiować i drukować dla własnego użytku w
            domowym zaciszu. Jednak, gdyby ktoś chciał je publikować na innych
            stronach, mediach to proszę o wcześniejszy kontakt ze mną za pomocą
            formularza. Indywidualnie rozpatrzę taką możliwość.
          </p>
        </div>
      </main>
    );
  }
}

export default Contact;
