import React, { Component } from "react";
import { Link } from "react-router-dom";
import "./main.scss";
import LatestData from "../../data/latest.json";

class Main extends Component {
  render() {
    return (
      <div>
        <main>
          <article className="main">
            <header className="header">
              <p className="header__text">Bajki terapeutyczne dla dzieci</p>
            </header>
            <div className="main__latest--heading">
              <h3>Najnowsze bajki</h3>
              <div className="main__latest">
                {LatestData.latest["fairy-tales"].map((latest, index) => {
                  const image =
                    require(`../../../public/img/${latest.slug}.jpg`) ||
                    require(`../../../public/img/jak-to-wszystko-sie-zaczelo.jpg`);
                  return (
                    index < 4 && (
                      <Link
                        key={index}
                        to={`/bajki/${latest.slug}`}
                        className="article__box main__latest--secondary"
                      >
                        <h1 className="article__header">{latest.header}</h1>
                        <p className="article__content">{latest.content}</p>
                        <img
                          className="article__image"
                          src={image}
                          alt="drobinek"
                        ></img>
                      </Link>
                    )
                  );
                })}
              </div>
            </div>
          </article>
          <header className="header__second">
            <p className="header__text">A dla starszych czytelników...</p>
          </header>
          <div className="main__latest--heading">
            <h3>Najnowsze artykuły</h3>
            <div>
              <div
                className="main__latest"
                // remove when there are more articles
                style={{ justifyContent: "center" }}
              >
                {LatestData.latest["articles"].map((latest, index) => {
                  const image =
                    require(`../../../public/img/${latest.slug}_small.jpg`) ||
                    require(`../../../public/img/jak-to-wszystko-sie-zaczelo.jpg`);

                  return (
                    index < 4 && (
                      <Link
                        key={index}
                        to={`/artykuly/${latest.slug}`}
                        className="article__box main__latest--secondary"
                        // remove when there are more articles
                        style={{ flex: "0" }}
                      >
                        <h1 className="article__header">{latest.header}</h1>
                        <p className="article__content">{latest.content}</p>
                        <img
                          className="article__image"
                          src={image}
                          alt="drobinek"
                        ></img>
                      </Link>
                    )
                  );
                })}
              </div>
              {/* uncomment when there are more articles */}
              {/* <div className="main__latest">
                  {LatestData.latest["articles"].map((latest, index) => {
                    return (
                      index > 2 &&
                      index < 6 && (
                        <Link
                          to={`/bajki/${latest.slug}`}
                          className="article__box main__latest--primary"
                        >
                          <img
                            className="main__latest--image"
                            src="http://ppp-raciborz.pl/images/Drobinek%20ma%20lekcje.jpg"
                            alt="drobinek"
                          ></img>
                          <div className="main__latest--text">
                            <h1 className="article__header">{latest.header}</h1>
                            <p className="article__content">{latest.content}</p>
                          </div>
                        </Link>
                      )
                    );
                  })}
                </div> */}
            </div>
            <div className="contact-form__text">
              <p>
                Wszystkie bajki, artykuły i grafiki na stronie alebaja.com.pl są
                mojego autorstwa. Można je kopiować i drukować dla własnego
                użytku w domowym zaciszu. Jednak, gdyby ktoś chciał je
                publikować na innych stronach, mediach to proszę o wcześniejszy
                kontakt ze mną za pomocą formularza. Indywidualnie rozpatrzę
                taką możliwość.
              </p>
            </div>
          </div>
        </main>
      </div>
    );
  }
}

export default Main;
