import "./App.scss";
import AboutUs from "./components/aboutUs/AboutUs";
import Content from "./components/content/Content.js";
import Post from "./components/post/Post";
import Main from "./components/main/Main.js";
import NoMatch from "./components/noMatch/NoMatch";
import Contact from "./components/contact/Contact";

const routes = {
  data: [
    {
      component: Main,
      exact: true,
      path: "/",
    },
    {
      component: Content,
      exact: true,
      path: "/bajki",
    },
    {
      component: Content,
      exact: true,
      path: "/artykuly",
    },
    {
      component: Post,
      path: "/bajki/:title",
    },
    {
      component: Post,
      path: "/artykuly/:title",
    },
    {
      component: AboutUs,
      path: "/o-mnie",
    },
    {
      component: Contact,
      path: "/kontakt",
    },
    {
      component: NoMatch,
      path: "*",
    },
  ],
};

export default routes;
