import React, { Component } from "react";
import "./cookieInfo.scss";

class CookieInfo extends Component {
  componentDidMount() {
    this.isCookieConsentVisible();
  }
  isCookieConsentVisible() {
    if (!localStorage.getItem("cookieconsent"))  {
      return true;
    } else {
      return false;
    }
  }

  setCookieConsent() {
    const cookieInfo = document.getElementsByClassName("cookie-info")[0];
    cookieInfo.style.display = "none";
    localStorage.setItem("cookieconsent", true);
  }
  render() {
    return (
      <div>
        {this.isCookieConsentVisible() && (
          <div className="cookie-info">
            <span>
              Nasza strona internetowa używa plików cookies (tzw. ciasteczka) w
              celach statystycznych, reklamowych oraz funkcjonalnych. Dzięki nim
              możemy indywidualnie dostosować stronę do twoich potrzeb. Każdy
              może zaakceptować pliki cookies albo ma możliwość wyłączenia ich w
              przeglądarce, dzięki czemu nie będą zbierane żadne informacje{" "}
            </span>
            <div
              className="cookie-info__close"
              onClick={() => {
                this.setCookieConsent();
              }}
            >
              Zrozumiano
            </div>
          </div>
        )}
      </div>
    );
  }
}

export default CookieInfo;
