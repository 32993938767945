import React, { Component } from "react";
import Header from "./components/header/Header.js";
import Footer from "./components/footer/Footer.js";
import "./App.scss";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import CookieInfo from "./components/cookieInfo/CookieInfo.js";
import Sprite from "../public/SVG/sprite.svg";
import routes from "./routes";
import Helmet from "react-helmet";

class App extends Component {
  constructor(props) {
    super(props);

    this.state = {
      visible: false,
      loading: true,
    };
  }

  componentDidMount() {
    const script = document.createElement("script");

    script.src =
      "https://connect.facebook.net/pl_PL/sdk.js#xfbml=1&version=v6.0";
    script.async = true;
    script.defer = true;
    script.crossorigin = "crossorigin";

    document.body.appendChild(script);

    window.addEventListener("scroll", this.handleScroll);

    setTimeout(() => this.setState({ loading: false }), 1500);
  }

  componentWillUnmount() {
    window.removeEventListener("scroll", this.handleScroll);
  }

  handleScroll = () => {
    const currentScrollPos = window.pageYOffset;
    const visible = currentScrollPos > 120;

    this.setState({
      visible,
    });
  };

  render() {
    return (
      routes,
      (
        <Router>
          <div className={this.state.loading ? "loading-logo" : ""}>
          </div>
          <div className="App">
            <div className="App-header">
              <Header />
            </div>
            <Switch>
              {routes.data.map((entry) => {
                return <Route key={entry} {...entry} />;
              })}
            </Switch>
            <Helmet>
              <meta charSet="utf-8" />
              <title>Alebaja - Darmowe bajki terapeutyczne i nie tylko</title>
            </Helmet>
            <CookieInfo />
            <Footer />
            <div
              className={
                this.state.visible ? "scroll-top" : "scroll-top--hidden"
              }
            >
              <a
                href="!#"
                onClick={(event) => {
                  event.preventDefault();
                  window.scrollTo(0, 0);
                }}
              >
                <svg className="scroll-top__icon">
                  <use href={Sprite + "#icon-arrow-up2"}></use>
                </svg>
              </a>
            </div>
          </div>
        </Router>
      )
    );
  }
}

export default App;
