import { createStore } from "redux";
// import rootReducer from "./reducers";

function fontSize(state = 24, action) {
  switch (action.type) {
    case "font":
      return { state };
    default:
      return state;
  }
}

let store = createStore(fontSize);
window.store = store;

export default store;
